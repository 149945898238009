import React, { useEffect, useState } from "react";
import {
    FormControl,
    Typography,
    Select,
    MenuItem,
    LinearProgress,
    Fade,
    Checkbox,
    ListItemText,
    List,
    ListItem,
    ListItemIcon,
    Button,
    TextField,
    Box,
    Grid,
    Snackbar,
    Card,
    Collapse,
    FormLabel,
} from "@material-ui/core/";

import { Moment } from "moment-timezone";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import { useVideExportRequest, useVideoExportData } from "src/hooks/useVideoExportData";
import { useAuth } from "src/contexts/AuthStateContext";
import { VideoExportPost } from "src/entities/VideoExportData";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { Site } from "../../../entities";
import "./_video-export.scss";
import { Alert, Skeleton } from "@material-ui/lab";
import DOMPurify from "dompurify";
import { Profile } from "src/types";
import { usesAmPm } from "src/services/userProfileService";

type Props = {
    site: Site;
    userProfile: Profile;
} & RouteComponentProps;

export const VideoExport = (props: Props) => {
    const { site, userProfile } = props;
    const { t } = useTranslation();
    const { exportVideoData, isLoading } = useVideoExportData({
        siteId: site.id,
        partnerId: site.partner.id,
    });
    const { logout } = useAuth();
    const {
        postVideoExportRequest,
        resetVideoExportUIStates,
        videoExportPostIsLoading,
        videoExportPostHasError,
        videoExportPostSuccess,
    } = useVideExportRequest({
        logout,
    });

    const { cameras, reasons } = exportVideoData || { cameras: [], reasons: [] };
    const [selectedCameras, setSelectedCameras] = useState<string[]>([]);
    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [selectedStartTime, setSelectedStartTime] = useState<Moment | null>(null);
    const [selectedEndTime, setSelectedEndTime] = useState<Moment | null>(null);
    const [notes, setNotes] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageSeverity, setMessageSeverity] = useState<Color | undefined>("info");
    const ampm = usesAmPm(userProfile.locale);

    const [showErrorCameras, setShowErrorCameras] = useState(false);
    const [showErrorDateTime, setShowErrorDateTime] = useState(false);
    const [errorDateTimeMessage, setErrorDateTimeMessage] = useState("");
    const [showErrorReasons, setShowErrorReasons] = useState(false);
    const [submittedOnce, setSubmittedOnce] = useState(false);

    const clearForm = () => {
        setSelectedReasons([]);
        setSelectedCameras([]);
        setSelectedDate(null);
        setSelectedStartTime(null);
        setSelectedEndTime(null);
        setNotes("");
    };

    const validateCamerasSelected = () => {
        return selectedCameras.length > 0;
    };

    const validateDatesExisting = () => {
        return selectedDate && selectedStartTime && selectedEndTime;
    };

    const validateDatesOrder = () => {
        return selectedStartTime && selectedStartTime.isBefore(selectedEndTime, "minute");
    };

    const validatReasonsSelected = () => {
        return selectedReasons.length > 0;
    };

    useEffect(() => {
        if (videoExportPostHasError) {
            setMessage(t("Entities.VideoExport.ErrorExportRequest"));
            setMessageSeverity("error");
            setShowMessage(true);
            setSubmittedOnce(false);
            resetVideoExportUIStates();
        }
        if (videoExportPostSuccess) {
            setMessage(t("Entities.VideoExport.Success"));
            setMessageSeverity("success");
            setShowMessage(true);
            setSubmittedOnce(false);
            resetVideoExportUIStates();
            clearForm();
        }

        if (validateCamerasSelected()) {
            setShowErrorCameras(false);
        } else if (submittedOnce) {
            setShowErrorCameras(true);
        }

        if (validatReasonsSelected()) {
            setShowErrorReasons(false);
        } else if (submittedOnce) {
            setShowErrorReasons(true);
        }

        if (validateDatesExisting() && validateDatesOrder()) {
            setShowErrorDateTime(false);
        } else if (submittedOnce) {
            setShowErrorDateTime(true);
        }
    }, [
        videoExportPostHasError,
        videoExportPostSuccess,
        selectedCameras,
        selectedReasons,
        selectedDate,
        selectedStartTime,
        selectedEndTime,
        submittedOnce,
    ]);

    const onCameraSelected = (cameraId: string) => () => {
        if (selectedCameras.includes(cameraId)) {
            setSelectedCameras(selectedCameras.filter((id) => id !== cameraId));
        } else {
            setSelectedCameras([...selectedCameras, cameraId]);
        }
    };

    const onSubmit = async () => {
        setSubmittedOnce(true);
        setShowErrorCameras(false);
        setShowErrorDateTime(false);
        setShowErrorReasons(false);

        let error = false;
        if (!validateCamerasSelected()) {
            setShowErrorCameras(true);
            error = true;
        }
        if (!validateDatesExisting()) {
            setShowErrorDateTime(true);
            setErrorDateTimeMessage("Entities.VideoExport.DateAndTimeRequired");
            error = true;
        } else if (!validateDatesOrder()) {
            setShowErrorDateTime(true);
            setErrorDateTimeMessage("Entities.VideoExport.ErrorStartTimeAfterEndTime");
            error = true;
        }
        if (!validatReasonsSelected()) {
            setShowErrorReasons(true);
            error = true;
        }

        if (error) return;

        if (selectedDate && selectedStartTime && selectedEndTime) {
            selectedDate.hour(0);
            selectedDate.minute(0);
            selectedDate.second(0);
            selectedDate.millisecond(0);

            const startTime = selectedDate.clone();
            startTime.add(selectedStartTime.hours(), "hours");
            startTime.add(selectedStartTime.minutes(), "minutes");
            startTime.add(selectedStartTime.seconds(), "seconds");

            const endTime = selectedDate.clone();
            endTime.add(selectedEndTime.hours(), "hours");
            endTime.add(selectedEndTime.minutes(), "minutes");
            endTime.add(selectedEndTime.seconds(), "seconds");

            const sanitizedNotes = DOMPurify.sanitize(notes);

            const request: VideoExportPost = {
                siteId: site.id,
                cameraIds: selectedCameras,
                reasons: selectedReasons,
                startTimeUtc: startTime.utc(),
                stopTimeUtc: endTime.utc(),
                details: sanitizedNotes,
            };

            await postVideoExportRequest(request);
        }
    };

    return (
        <div className="video-export">
            <Fade in={isLoading || videoExportPostIsLoading} timeout={{ enter: 700, exit: 400 }}>
                <LinearProgress />
            </Fade>
            <Grid container direction="row" style={{ marginTop: "20px" }} spacing={3}>
                <Grid item xs={12}>
                    <Card
                        style={{ backgroundColor: "#252525", marginBottom: 0 }}
                        className={
                            showErrorCameras ? "video-export__form-error__box" : "video-export__form-normal__box"
                        }
                    >
                        <Box m={3}>
                            <Typography display="inline" variant="h6">
                                {t("Entities.VideoExport.SelectCameras")}
                            </Typography>
                            <Typography display="inline" variant="body1">
                                &nbsp; *
                            </Typography>
                        </Box>
                        <Box mt={2} mb={1}>
                            <List style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                {!isLoading ? (
                                    cameras.map((camera) => (
                                        <ListItem
                                            key={camera.id}
                                            role={undefined}
                                            dense
                                            button
                                            onClick={
                                                !isLoading && !videoExportPostIsLoading
                                                    ? onCameraSelected(camera.id)
                                                    : undefined
                                            }
                                            classes={{ root: "video-export__camera-list-item" }}
                                        >
                                            <ListItemIcon>
                                                <Box ml={2}>
                                                    <Checkbox
                                                        edge="start"
                                                        disabled={isLoading || videoExportPostIsLoading}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        checked={selectedCameras.includes(camera.id)}
                                                    ></Checkbox>
                                                </Box>
                                            </ListItemIcon>
                                            <ListItemText primary={camera.name} />
                                        </ListItem>
                                    ))
                                ) : (
                                    <>
                                        <Skeleton variant="rect" height={30} style={{ marginBottom: 10 }} />
                                        <Skeleton variant="rect" height={30} style={{ marginBottom: 10 }} />
                                        <Skeleton variant="rect" height={30} style={{ marginBottom: 10 }} />
                                    </>
                                )}
                            </List>
                        </Box>
                    </Card>
                    <Box>
                        <Collapse in={showErrorCameras}>
                            <Fade in={showErrorCameras}>
                                <Alert
                                    className="video-export__form-error__label"
                                    elevation={6}
                                    variant="outlined"
                                    severity="error"
                                >
                                    {t("Entities.VideoExport.ErrorNoCameraSelected")}
                                </Alert>
                            </Fade>
                        </Collapse>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card
                        className={
                            showErrorDateTime ? "video-export__form-error__box" : "video-export__form-normal__box"
                        }
                        style={{ backgroundColor: "#252525", marginBottom: 0, minHeight: 266 }}
                    >
                        <Box m={3}>
                            <Typography variant="h6">{t("Entities.VideoExport.SelectDateAndTime")}</Typography>
                        </Box>
                        <Box m={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={"sv-se"}>
                                <Grid container direction="column">
                                    <Box mb={2} mt={0}>
                                        <DatePicker
                                            style={{ marginTop: 0 }}
                                            disableFuture
                                            value={selectedDate}
                                            label={t("Entities.VideoExport.SelectDate")}
                                            margin="normal"
                                            DialogProps={{ fullWidth: true }}
                                            onChange={(date: MaterialUiPickersDate) => {
                                                setSelectedDate(date);
                                            }}
                                            disabled={isLoading || videoExportPostIsLoading}
                                            required
                                        />
                                    </Box>
                                    <Grid container direction="row">
                                        <Box m={2} marginLeft={0} marginRight={4}>
                                            <TimePicker
                                                value={selectedStartTime}
                                                required
                                                label={t("Entities.VideoExport.StartTime")}
                                                DialogProps={{ fullWidth: true }}
                                                ampm={ampm}
                                                onChange={(time: MaterialUiPickersDate) => {
                                                    setSelectedStartTime(time);
                                                }}
                                                disabled={isLoading || videoExportPostIsLoading}
                                            />
                                        </Box>
                                        <Box m={2} marginLeft={0}>
                                            <TimePicker
                                                value={selectedEndTime}
                                                required
                                                label={t("Entities.VideoExport.EndTime")}
                                                DialogProps={{ fullWidth: true }}
                                                ampm={ampm}
                                                onChange={(date: MaterialUiPickersDate) => {
                                                    setSelectedEndTime(date);
                                                }}
                                                disabled={isLoading || videoExportPostIsLoading}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Card>
                    <Box width={300}>
                        <Collapse in={showErrorDateTime}>
                            <Fade in={showErrorDateTime}>
                                <Alert
                                    className="video-export__form-error__label"
                                    elevation={6}
                                    variant="outlined"
                                    severity="error"
                                >
                                    {t(errorDateTimeMessage)}
                                </Alert>
                            </Fade>
                        </Collapse>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card
                        className={
                            showErrorReasons ? "video-export__form-error__box" : "video-export__form-normal__box"
                        }
                        style={{ backgroundColor: "#252525", marginBottom: 0, minHeight: 266 }}
                    >
                        <Box m={3}>
                            <Typography variant="h6">{t("Entities.VideoExport.SelectReasons")}</Typography>
                        </Box>
                        <Box m={3} mb={5} pt={2}>
                            <Select
                                multiple
                                displayEmpty
                                value={selectedReasons}
                                onChange={(e) => {
                                    setSelectedReasons(e.target.value as string[]);
                                }}
                                renderValue={(selected) =>
                                    !!(selected as string[]).length ? (
                                        (selected as string[]).join(", ")
                                    ) : (
                                        <FormLabel>{t("Entities.VideoExport.SelectReasons")} *</FormLabel>
                                    )
                                }
                                className="video-export__reason-select"
                                style={{ width: 400 }}
                                disabled={isLoading || videoExportPostIsLoading}
                            >
                                {reasons.map((reason) => (
                                    <MenuItem key={reason} value={reason}>
                                        <Checkbox checked={selectedReasons.includes(reason)} />
                                        <ListItemText primary={reason} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box m={3}>
                            <TextField
                                value={notes}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                                placeholder={t("Entities.VideoExport.OtherNotesPlaceholder")}
                                variant="outlined"
                                style={{ width: 400 }}
                                disabled={isLoading || videoExportPostIsLoading}
                            />
                        </Box>
                    </Card>
                    <Collapse in={showErrorReasons}>
                        <Fade in={showErrorReasons}>
                            <Alert
                                className="video-export__form-error__label"
                                elevation={6}
                                variant="outlined"
                                severity="error"
                            >
                                {t("Entities.VideoExport.ErrorNoReasonSelected")}
                            </Alert>
                        </Fade>
                    </Collapse>
                </Grid>
            </Grid>
            <Box mb={2} mt={4}>
                <FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSubmit();
                        }}
                        disabled={isLoading || videoExportPostIsLoading}
                    >
                        {t("Entities.VideoExport.SubmitButton")}
                    </Button>
                </FormControl>
            </Box>
            <Box my={2} mb={12}>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={() => {
                        setShowMessage(false);
                        setMessage("");
                    }}
                    open={showMessage}
                >
                    <MuiAlert elevation={6} variant="filled" severity={messageSeverity}>
                        {t(message)}
                    </MuiAlert>
                </Snackbar>
            </Box>
        </div>
    );
};
