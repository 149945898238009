import { LDClient, LDFlagSet, LDOptions, initialize } from "ldclient-js";
import type { Observable, Subject } from "rxjs";
import { BehaviorSubject, from as observableFrom } from "rxjs";
import { launchDarklyKey } from "src/settings";
import { mapValues, uniqueId } from "lodash";
import { Profile } from "src/types";

export class FeatureFlagService {
    private static instance: FeatureFlagService;
    public ldClient: LDClient;
    public flags: LDFlagSet;
    private readonly flagsChangeSubject: Subject<LDFlagSet>;
    private readonly flagsObservableInstance: Observable<LDFlagSet>;

    private constructor(userProfile: Profile) {
        this.flags = {};
        this.ldClient = {} as LDClient;
        this.flagsChangeSubject = new BehaviorSubject(this.flags);
        this.flagsObservableInstance = observableFrom(this.flagsChangeSubject);

        if (!launchDarklyKey) {
            console.log("Launch Darkly key is not provided, all features will be disabled.");
            return;
        }

        this.ldClient = initialize(launchDarklyKey, {
            anonymous: true,
            key: uniqueId(),
        });

        this.ldClient.on("change", this.updateFlags.bind(this));
        this.ldClient.on("ready", this.setFlags.bind(this));

        if (userProfile) {
            this.ldClient
                .identify({
                    key: userProfile.email,
                    custom: {
                        partners: userProfile.partners.map((partner) => partner.id),
                    },
                })
                .catch((error) => {
                    console.error("Failed to identify user with Launch Darkly", error);
                });
        }
    }

    public static getInstance(userProfile: Profile): FeatureFlagService {
        if (!FeatureFlagService.instance) {
            FeatureFlagService.instance = new FeatureFlagService(userProfile);
        }

        return FeatureFlagService.instance;
    }

    public flagsObservable(): Observable<LDFlagSet> {
        return this.flagsObservableInstance;
    }

    public waitForLDClient(): Promise<void> {
        if (this.ldClient) {
            return this.ldClient.waitUntilReady();
        }
        return Promise.resolve();
    }

    public getFlag(name: string): boolean {
        return Boolean(this.flags[name]);
    }

    public track(message: string): void {
        return this.ldClient.track(message);
    }

    private updateFlags(flags: LDOptions) {
        Object.assign(
            this.flags,
            mapValues(flags, (v) => (v as any).current),
        );

        this.flagsChangeSubject.next(this.flags);
    }

    private setFlags() {
        this.flags = this.ldClient.allFlags();
        this.flagsChangeSubject.next(this.flags);
    }
}
