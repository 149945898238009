export enum Permission {
    DevelopmentTesting = 999,

    GlobalSysAdmin = 1,
    GlobalMonitoring = 2,

    PartnerSettings = 101,
    PartnerOperations = 102,
    PartnerMonitoring = 103,

    SiteBilling = 3,
    SiteInformation = 6,
    SiteConfiguration = 7,
    SiteFunctionalConfiguration = 8,
    SiteScheduleExceptions = 9,

    AlarmHandling = 10,
    SiteVideoExport = 11,
}
