import { apiUrl } from "src/settings";
import { VideoExportPost } from "src/entities/VideoExportData";
import { fetchWithIntercept } from "./fetchService";

export const requestVideoExport = async (videoExportPost: VideoExportPost, logout: () => void) => {
    const url = `${apiUrl}/videoExports`;

    try {
        const response = await fetchWithIntercept(url, "POST", logout, videoExportPost);
        if (response.status === 200) {
            return response.ok;
        }

        throw new Error(response.statusText);
    } catch (err) {
        throw new Error(err as string);
    }
};
