import { createContext, useContext } from "react";
import { Profile, SaveDataTransferStatus } from "src/types";

export type UserProfileContextType = {
    saveProfileStatus: SaveDataTransferStatus;
    userProfile: Profile | undefined;
    userPermissions: UserProfilePermissions;
    updateUserProfile: (updatedProfile: Profile) => Promise<void>;
};

export type UserProfilePermissions = {
    viewSiteDetails: boolean;
    exportVideo: boolean;
};

export const UserProfileContext = createContext<Partial<UserProfileContextType>>({});

export function useUserProfile() {
    return useContext(UserProfileContext) as UserProfileContextType;
}
