import { Camera, VideoExportData } from "src/entities";
import { apiUrl } from "src/settings";
import { useMemo, useState } from "react";
import { VideoExportPost } from "src/entities/VideoExportData";
import { requestVideoExport } from "src/services/videoExportService";
import { useData } from "./useData";

const useSiteCameras = (siteId: string) => {
    const url = `${apiUrl}/videoexports/cameras?siteId=${siteId}`;
    const { data: cameras, isLoading: camerasIsLoading, hasError: camerasHasError } = useData<Camera[]>(url);
    return { cameras, camerasIsLoading, camerasHasError };
};

const useReasons = (partnerId: string) => {
    const url = `${apiUrl}/videoexports/reasons?accountId=${partnerId}`;
    const { data: reasons, isLoading: reasonsIsLoading, hasError: reasonsHasError } = useData<string[]>(url);
    return { reasons, reasonsIsLoading, reasonsHasError };
};

export const useVideoExportData = (props: { siteId: string; partnerId: string }) => {
    const { siteId, partnerId } = props;
    const { cameras, camerasIsLoading, camerasHasError } = useSiteCameras(siteId);
    const { reasons, reasonsIsLoading, reasonsHasError } = useReasons(partnerId);

    const data = useMemo(() => {
        if (cameras && reasons) {
            return { cameras, reasons } as VideoExportData;
        }
        return undefined;
    }, [cameras, reasons]);

    return {
        exportVideoData: data,
        isLoading: camerasIsLoading || reasonsIsLoading,
        hasError: camerasHasError || reasonsHasError,
    };
};

export const useVideExportRequest = (props: { logout: () => void }) => {
    const { logout } = props;
    const [videoExportPostIsLoading, setVideoExportPostIsLoading] = useState(false);
    const [videoExportPostHasError, setVideoExportPostHasError] = useState(false);
    const [videoExportPostSuccess, setVideoExportPostSuccess] = useState(false);

    const postVideoExportRequest = async (videoExportPost: VideoExportPost) => {
        setVideoExportPostIsLoading(true);
        setVideoExportPostHasError(false);
        setVideoExportPostSuccess(false);

        try {
            await requestVideoExport(videoExportPost, logout);
            setVideoExportPostSuccess(true);
        } catch (err) {
            setVideoExportPostHasError(true);
        }

        setVideoExportPostIsLoading(false);
    };

    const resetVideoExportUIStates = () => {
        setVideoExportPostIsLoading(false);
        setVideoExportPostHasError(false);
        setVideoExportPostSuccess(false);
    };

    return {
        videoExportPostIsLoading,
        videoExportPostHasError,
        videoExportPostSuccess,
        postVideoExportRequest,
        resetVideoExportUIStates,
    };
};
