import moment from "moment-timezone";
import { VideoStream } from "src/entities";
import { apiUrl } from "src/settings";
import { useData } from "./useData";

export const useVideoStreams = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/videostreams`;
    const { data: videoStreamList, isLoading: videoStreamsIsLoading, hasError: videoStreamsHasError } = useData<
        VideoStream[]
    >(url);

    const videoStreams = videoStreamList?.map((videoStream) => ({
        alarmZones: videoStream.alarmZones,
        id: videoStream.id,
        name: videoStream.name,
        dtLastImageUpdate: moment.utc(videoStream.dtLastImageUpdate).toDate(),
        periodicImageUploadStatus: videoStream.periodicImageUploadStatus,
        images: videoStream.images,
        latestImageUpdateStatus: videoStream.latestImageUpdateStatus,
        siteEquipmentName: videoStream.siteEquipmentName,
        index: videoStream.index,
        dtCreated: moment.utc(videoStream.dtCreated).toDate(),
        dtLastState: moment.utc(videoStream.dtLastState).toDate(),
    }));

    return { videoStreams, videoStreamsIsLoading, videoStreamsHasError };
};
