import React, { FunctionComponent, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { activate2fa, fetchUserProfile, updateUserProfile } from "src/services/userProfileService";
import { Profile, SaveDataTransferStatus } from "src/types";
import { getUserProfile, PatchDelta, storeUserProfile } from "src/utils";
import { FeatureFlagService } from "src/services/featureFlagService";
import { useAuth } from "./AuthStateContext";
import { UserProfileContext, UserProfileContextType, UserProfilePermissions } from "./UserProfileContext";

export const UserProfileContextProvider: FunctionComponent = ({ children }: any) => {
    const [userProfile, setUserProfile] = useState(getUserProfile());
    const [saveProfileStatus, setSaveProfileStatus] = useState<SaveDataTransferStatus>({
        isLoading: false,
        hasError: false,
        errorMessage: "",
    });
    const { auth, isLoggedIn, logout } = useAuth();
    const [userPermissions, setUserPermissions] = useState<UserProfilePermissions>({
        viewSiteDetails: false,
        exportVideo: false,
    });

    useEffect(() => {
        const fetchAndSetUser = async () => {
            const profile = await fetchUserProfile(logout);
            setUserProfile(profile);
            storeUserProfile(profile);
        };

        if (isLoggedIn && auth?.accessToken) {
            fetchAndSetUser().catch(console.error);
        }
    }, [auth, isLoggedIn]);

    useEffect(() => {
        if (userProfile) {
            const featureFlagService = FeatureFlagService.getInstance(userProfile);
            featureFlagService.flagsObservable().subscribe(() => {
                const flag = featureFlagService.getFlag("video-export-enabled");

                const permissions = {
                    viewSiteDetails:
                        userProfile.userPermissions.partnerPermissions?.includes("SiteInformation") ||
                        userProfile.userPermissions.sitePermissions?.includes("SiteInformation"),
                    exportVideo:
                        (userProfile.userPermissions.partnerPermissions?.includes("SiteVideoExport") ||
                            userProfile.userPermissions.sitePermissions?.includes("SiteVideoExport")) &&
                        flag,
                };
                setUserPermissions(permissions);
            });
        }
    }, [userProfile]);

    const value: UserProfileContextType = {
        saveProfileStatus,
        userProfile,
        userPermissions,
        updateUserProfile: async (updatedProfile: Profile) => {
            if (userProfile) {
                try {
                    const patchDelta = PatchDelta.difference(userProfile, updatedProfile);
                    let profileResponse;

                    if (!isEmpty(patchDelta)) {
                        setSaveProfileStatus({
                            isLoading: true,
                            hasError: false,
                            errorMessage: "",
                        });

                        profileResponse = await updateUserProfile(patchDelta, logout);

                        setUserProfile(profileResponse);
                        storeUserProfile(profileResponse);

                        setSaveProfileStatus({
                            isLoading: false,
                            hasError: false,
                            errorMessage: "",
                        });
                    }

                    if (patchDelta.enableTwoFactorAuth) {
                        await activate2fa(userProfile.email, logout);
                    }
                } catch (error) {
                    const errorMessage = (error as Error).message;

                    setSaveProfileStatus({
                        isLoading: false,
                        hasError: true,
                        errorMessage,
                    });
                    // throw new Error(`Patch user profile error: ${error}`);
                }
            } else {
                throw new Error("UserProfile is undefined");
            }
        },
    };

    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};
