import { useMemo } from "react";
import { Site, SiteDetails } from "src/entities";
import { apiUrl } from "src/settings";
import { useData } from "./useData";

const useSite = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/slim`;
    const { data: site, isLoading: siteIsLoading, hasError: siteHasError } = useData<Site>(url);
    return { site, siteIsLoading, siteHasError };
};

export const useSiteDetailsSlim = (props: { siteId: string; siteDetailsEnabled: boolean }) => {
    const { siteId } = props;
    const { site, siteIsLoading, siteHasError } = useSite(siteId);
    const siteDetails = useMemo(() => {
        if (!props.siteDetailsEnabled) {
            return { site, configurationGroups: [], siteId, videoStreams: [] } as SiteDetails;
        }

        if (site) {
            return {
                site,
                siteId,
            } as SiteDetails;
        }
        return undefined;
    }, [site]);

    if (!props.siteDetailsEnabled) {
        return {
            siteDetails,
            siteDetailsIsLoading: siteIsLoading,
            siteDetailsHasError: siteHasError,
        };
    }

    return {
        siteDetails,
        siteDetailsIsLoading: siteIsLoading,
        siteDetailsHasError: siteHasError,
    };
};
