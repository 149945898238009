import { ConfigurationGroup } from "src/entities";
import { apiUrl } from "src/settings";
import { useData } from "./useData";

export const useConfigurations = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/configurations/withSchedules`;
    const { data: configurations, isLoading: configurationsIsLoading, hasError: configurationsHasError } = useData<
        ConfigurationGroup[]
    >(url);
    return { configurations, configurationsIsLoading, configurationsHasError };
};
