import React, { useEffect, useMemo, useState } from "react";

import {
    Avatar,
    Paper,
    Typography,
    LinearProgress,
    Fade,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableSortLabel,
    Menu,
    MenuItem,
    Button,
    Icon,
    Dialog,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

import { SiteExceptions } from "src/components";
import { Site } from "src/entities";
import { useSiteList } from "src/hooks/useSiteList";
import { useHistory } from "react-router-dom";
import { useGlobalSearch } from "src/contexts/GlobalSearchContext";
import { useDialogModes } from "src/hooks/useDialogModes";
import { useUserProfile } from "src/contexts/UserProfileContext";

import "./Dashboard.scss";

const SkeletonCell = () => (
    <TableCell colSpan={4}>
        <Fade in timeout={{ enter: 800, exit: 400 }}>
            <Skeleton variant="rect" height={40} />
        </Fade>
    </TableCell>
);

const SkeletonRow = () => (
    <TableRow className="dashboard__table-row">
        <TableCell>
            <Fade in timeout={{ enter: 800, exit: 400 }}>
                <Skeleton variant="circle" height={40} width={40} />
            </Fade>
        </TableCell>
        <SkeletonCell />
    </TableRow>
);

export const Dashboard = () => {
    const { sites, sitesHasError, sortSites, sortProperty, sortDirection, siteListIsLoading } = useSiteList();
    const { userProfile } = useUserProfile();
    const { globalSearchTerm } = useGlobalSearch();
    const history = useHistory();
    const { t } = useTranslation();

    const [exceptionDialogOpen, setExceptionDialogOpen] = useState(false);
    const [menuSite, setMenuSite] = useState<Site | undefined>();
    const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | undefined>();
    const [menuAvailable, setMenuAvailable] = useState(false);
    const [siteLinkEnabled, setSiteLinkEnabled] = useState(false);

    const { fullscreen } = useDialogModes();

    const onSelectSite = (siteId: string) => {
        if (siteLinkEnabled) {
            history.push(`sites/${siteId}`);
        }
    };

    const filteredSites = useMemo(() => {
        if (globalSearchTerm) {
            return sites.filter((s) => s.name.toLowerCase().includes(globalSearchTerm));
        }

        return sites;
    }, [sites, globalSearchTerm]);

    useEffect(() => {
        if (userProfile) {
            const hasSiteScheduleExceptionsPermissions =
                userProfile.userPermissions?.partnerPermissions?.includes("SiteScheduleExceptions") ||
                userProfile.userPermissions?.sitePermissions?.includes("SiteScheduleExceptions");

            setMenuAvailable(!!hasSiteScheduleExceptionsPermissions);

            const hasSiteInformationPermissions =
                userProfile.userPermissions?.partnerPermissions?.includes("SiteInformation") ||
                userProfile.userPermissions?.sitePermissions?.includes("SiteInformation") ||
                userProfile.userPermissions?.sitePermissions?.includes("SiteVideoExport");

            setSiteLinkEnabled(!!hasSiteInformationPermissions);
        }
    }, [userProfile]);

    const sitesShownString = () => {
        if (filteredSites && sites) {
            if (sites.length !== filteredSites.length) {
                return t("Entities.Sites.SitesShown", { sitesShown: filteredSites.length, sitesTotal: sites.length });
            }

            return sites.length;
        }

        return "";
    };

    return (
        <div className="dashboard">
            <ul className="breadcrumbs">
                <li>{t("Gui.Manage.SideMenu.Overview")}</li>
            </ul>
            {siteListIsLoading ? <LinearProgress /> : null}
            <React.Fragment>
                <Paper>
                    {menuAnchorElement ? (
                        <Menu
                            open
                            keepMounted
                            anchorEl={menuAnchorElement}
                            onClose={() => {
                                setMenuSite(undefined);
                                setMenuAnchorElement(undefined);
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setExceptionDialogOpen(true);
                                    setMenuAnchorElement(undefined);
                                }}
                            >
                                <Icon className="fa fa-eye-slash" />
                                {t("Entities.ScheduleException.ScheduleExceptions")}
                            </MenuItem>
                        </Menu>
                    ) : null}
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow className="dashboard__table-row">
                                <TableCell align="left" colSpan={2}>
                                    <Typography variant="overline">
                                        {t("Entities.Site.Sites")} ({sitesShownString()})
                                    </Typography>
                                </TableCell>
                                <TableCell colSpan={2} align="center">
                                    <Typography variant="overline">{t("Entities.Events.Events")}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow className="dashboard__table-row">
                                <TableCell />
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={sortProperty === "name"}
                                        direction={sortDirection}
                                        onClick={() => sortSites("name")}
                                        style={{ margin: 0 }}
                                    >
                                        <Typography variant="button">{t("Entities.Common.Name")}</Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" className="dashboard__table-number-cell">
                                    <TableSortLabel
                                        active={sortProperty === "eventsLastWeek"}
                                        direction={sortDirection}
                                        onClick={() => sortSites("eventsLastWeek")}
                                        style={{ margin: 0 }}
                                    >
                                        <Typography variant="button">{t("Entities.Events.LastWeek")}</Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" className="dashboard__table-number-cell">
                                    <TableSortLabel
                                        active={sortProperty === "eventsLastDay"}
                                        direction={sortDirection}
                                        onClick={() => sortSites("eventsLastDay")}
                                        style={{ margin: 0 }}
                                    >
                                        <Typography variant="button">{t("Entities.Events.LastDay")}</Typography>
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sitesHasError && (
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Error Loading Sites</TableCell>
                                </TableRow>
                            )}
                            {filteredSites.length === 0 && !siteListIsLoading ? (
                                <TableRow>
                                    <TableCell />
                                    <TableCell>{t("Entities.Site.NoSites")}</TableCell>
                                </TableRow>
                            ) : null}
                            {siteListIsLoading ? (
                                <React.Fragment>
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                    <SkeletonRow />
                                </React.Fragment>
                            ) : null}
                            {filteredSites?.map((site: Site) => (
                                <TableRow
                                    className="dashboard__table-row dashboard__table-row--pointer"
                                    key={site.id}
                                    hover
                                    onClick={() => onSelectSite(site.id)}
                                >
                                    <TableCell>
                                        <Avatar>{site.name[0]}</Avatar>
                                    </TableCell>
                                    <TableCell size="small">
                                        <Box
                                            component="div"
                                            textOverflow="ellipsis"
                                            width={{ xs: 100, sm: 250, md: "100%" }}
                                        >
                                            <Typography variant="body2" noWrap>
                                                {site.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography className="dashboard__table-cell--text-primary">
                                            {site.eventsLastWeek != null && site.eventsLastWeek >= 0
                                                ? site.eventsLastWeek
                                                : "-"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography className="dashboard__table-cell--text-secondary">
                                            {site.eventsLastDay != null && site.eventsLastDay >= 0
                                                ? site.eventsLastDay
                                                : "-"}
                                        </Typography>
                                        {menuAvailable ? (
                                            <Button
                                                className="dashboard__menu-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setMenuSite(site);
                                                    setMenuAnchorElement(e.target as HTMLElement);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </Button>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
            {menuSite && exceptionDialogOpen && (
                <Dialog open={menuSite && exceptionDialogOpen} fullScreen={fullscreen} fullWidth maxWidth="md">
                    <SiteExceptions
                        site={menuSite}
                        onClose={() => {
                            setMenuAnchorElement(undefined);
                            setMenuSite(undefined);
                            setExceptionDialogOpen(false);
                        }}
                    />
                </Dialog>
            )}
        </div>
    );
};
