import React, { useEffect, useState } from "react";

import { Divider, Tabs, LinearProgress, Fade } from "@material-ui/core/";
import { useTranslation } from "react-i18next";
import { Link, Redirect, RouteComponentProps, Switch } from "react-router-dom";

import { Site } from "src/entities";
import { useUserProfile } from "src/contexts/UserProfileContext";
import { SiteAlarms } from "./SiteAlarms";
import { LinkTab, PropsRoute } from "../Core";
import { SiteOverview } from "./SiteOverview";
import { VideoExport } from "./VideoExport";

import "./SiteDetails.scss";
import { useSiteDetailsSlim } from "src/hooks/useSiteDetailsSlim";

export const SiteDetails = (props: RouteComponentProps) => {
    const { location, match } = props;
    const { userProfile, userPermissions } = useUserProfile();
    const [siteId] = useState((match.params as any).id);
    const [site, setSite] = useState<Site | undefined>(undefined);
    const [currentUrl] = useState(match.url);
    const [siteDetailsEnabled, setSiteDetailsEnabled] = useState(false);
    const [siteVideoExportEnabled, setSiteVideoExportEnabled] = useState(false);
    const { t } = useTranslation();
    const { siteDetails, siteDetailsIsLoading, siteDetailsHasError } = useSiteDetailsSlim({
        siteId,
        siteDetailsEnabled,
    });
    const [activeRoute, setActiveRoute] = useState("");

    const getActiveRoute = () => {
        let route = currentUrl;

        if (location.pathname.startsWith(`${route}/alarms`)) {
            route += "/alarms";
        } else if (location.pathname.startsWith(`${route}/export`)) {
            route += "/export";
        }

        return route;
    };

    useEffect(() => {
        if (userProfile && userPermissions) {
            if (!userPermissions.viewSiteDetails && userPermissions.exportVideo) {
                props.history.push(`${currentUrl}/export`);
                setActiveRoute(currentUrl + "/export");
            } else {
                setActiveRoute(getActiveRoute());
            }

            setSiteDetailsEnabled(userPermissions.viewSiteDetails);
            setSiteVideoExportEnabled(userPermissions.exportVideo);
        }
    }, [userProfile]);

    useEffect(() => {
        if (siteDetails) {
            setSite(siteDetails.site);
        }
    }, [siteDetails]);

    useEffect(() => {
        setActiveRoute(getActiveRoute());
    }, [match]);

    return (
        <div className="site-details">
            <ul className="breadcrumbs">
                <li>
                    <Link to="/Dashboard">{t("Gui.Manage.SideMenu.Overview")}</Link>
                </li>
                <li>{site?.name}</li>
            </ul>
            <Divider light={true} />
            <Fade in={!site} timeout={{ enter: 700, exit: 400 }}>
                <LinearProgress />
            </Fade>
            <Tabs className="site-details__tab" value={activeRoute}>
                {siteDetailsEnabled ? (
                    <LinkTab label={t("Entities.Site.Site")} to={`${currentUrl}`} value={`${currentUrl}`} />
                ) : null}
                {siteDetailsEnabled ? (
                    <LinkTab
                        label={t("Entities.Events.Alarms")}
                        to={`${currentUrl}/alarms`}
                        value={`${currentUrl}/alarms`}
                    />
                ) : null}
                {siteVideoExportEnabled ? (
                    <LinkTab
                        label={t("Entities.VideoExport.Title")}
                        to={`${currentUrl}/export`}
                        value={`${currentUrl}/export`}
                    />
                ) : null}
            </Tabs>
            {siteDetailsHasError ? (
                <div className="site-details__notice notice notice--critical">{t("Error.Loading.Site")}</div>
            ) : null}
            {site ? (
                <Switch>
                    {siteDetailsEnabled ? (
                        <PropsRoute
                            path={`${currentUrl}`}
                            exact={true}
                            component={SiteOverview}
                            withProps={{ site, siteDetails, siteDetailsIsLoading, hookProps: {} }}
                        />
                    ) : null}
                    {siteDetailsEnabled ? (
                        <PropsRoute
                            path={`${currentUrl}/alarms`}
                            component={SiteAlarms}
                            withProps={{ site, test: 1 }}
                        />
                    ) : null}
                    {siteVideoExportEnabled ? (
                        <PropsRoute
                            path={`${currentUrl}/export`}
                            component={VideoExport}
                            withProps={{ site, userProfile }}
                        />
                    ) : null}
                    <Redirect to={`${currentUrl}`} />
                </Switch>
            ) : null}
        </div>
    );
};
